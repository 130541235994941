<template>
  <success-page />
</template>

<script>
import SuccessPage from '../components/SuccessPage.vue'

  export default {
    name: 'Success',

    components: {
      SuccessPage
    },
  }
</script>
