<template>
  <v-container>
    <v-row class="text-center mt-2">
      <v-col cols="3" class="d-none d-lg-flex">
      </v-col>
      <v-col>
          <v-alert class="my-6" dense text outlined type="success">
              <p class="text-h6 py-3 text--white no-margin">
                  Ton inscription à bien été enregistré nous allons revenir vers toi <br class="d-none d-lg-flex"> sur ton mail: {{this.$route.query.mail}}, alors reste à l'affût 😉
              </p>
          </v-alert>
      </v-col>
      <v-col cols="3" class="d-none d-lg-flex">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SuccessPage',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.no-margin{
    margin-bottom: 0;
}
</style>